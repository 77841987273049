.header {
    padding: theme('spacing.150');
    border-bottom: 1px solid theme('colors.decorators.subtle');
}

.gridOne {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.150');
    padding: theme('spacing.150');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-2;
    }
}

.gridTwo {
    @apply grid-cols-2;

    display: grid;
    gap: theme('spacing.150');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-1;
    }
}
