.labelWrap {
    display: flex;
    flex-direction: column;

    * + * {
        margin-top: theme('fields.spacing.default');
    }
}

.field {
    @apply type-paragraph placeholder-decorators-subtle;

    padding: theme('fields.padding.default');
    background: theme('fields.default.background');
    border: theme('fields.default.border');
    opacity: theme('fields.default.opacity');
    fill: theme('fields.default.textColor');
    font-size: theme('fontSize.100');
    transition: border-color theme('transitionDuration.fast') theme('transitionTimingFunction.default');

    &:hover {
        background: theme('fields.hover.background');
        border: theme('fields.hover.border');
        opacity: theme('fields.hover.opacity');
        fill: theme('fields.hover.textColor');
    }

    &:focus {
        background: theme('fields.focus.background');
        border: theme('fields.focus.border');
        opacity: theme('fields.focus.opacity');
        fill: theme('fields.focus.textColor');
    }

    &:focus-within {
        outline: none;
    }

    &:disabled {
        background: theme('fields.disabled.background');
        border: theme('fields.disabled.border');
        opacity: theme('fields.disabled.opacity');
        fill: theme('fields.disabled.textColor');
        cursor: not-allowed;
    }

    &[data-lpignore='true'] {

        ~ [data-lastpass-icon-root] {
            display: none !important;
        }
    }
}

.fieldValid {
    background: theme('fields.valid.background');
    border: theme('fields.valid.border');
    opacity: theme('fields.valid.opacity');
    fill: theme('fields.valid.textColor');
}

.fieldInvalid {
    background: theme('fields.error.background');
    border: theme('fields.error.border');
    opacity: theme('fields.error.opacity');
    fill: theme('fields.error.textColor');
}

.defaultBorderRadius {
    border-radius: theme('fields.borderRadius.default');
}

.smallBorderRadius {
    border-radius: theme('fields.borderRadius.small');
}

.largeBorderRadius {
    border-radius: theme('fields.borderRadius.large');
}

.select {
    @apply appearance-none;

    width: 100%;
    padding-right: theme('spacing.200');
    cursor: pointer;

    + img {
        position: absolute;
        top: 50%;
        right: theme('spacing.075');
        margin: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }
}

.small {
    padding: theme('fields.padding.small');
}