.header {
    padding: theme('spacing.150');
    border-bottom: 1px solid theme('colors.decorators.subtle');
}

.gridOne {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.150');
    padding: theme('spacing.150');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-3;
    }

    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-4;

        gap: theme('spacing.050');
    }
}

.gridTwo {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.150');

    @media (min-width: theme('screens.md')) {
        gap: theme('spacing.200');
    }

    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-3 col-span-2;

        gap: theme('spacing.050');
    }
}
