.article {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.rhythm-xsmall');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-2;

        grid-template-rows: repeat(3, auto);
        row-gap: theme('spacing.rhythm-xsmall');
        column-gap: theme('spacing.200');
    }
}

.image {
    width: 10rem;
    margin: 0 auto;

    @media (min-width: theme('screens.md')) {
        grid-area: 2 / 1 / 3 / 2; /* Position left column, below header. */
        width: 12.5rem;
    }

    @media (min-width: theme('screens.lg')) {
        width: 17.5rem;
    }
}

.header {
    @media (min-width: theme('screens.md')) {
        grid-area: 1 / 1 / 2 / 3; /* Position top, across both columns. */
    }
}

.selector {
    @media (min-width: theme('screens.md')) {
        grid-area: 2 / 2 / 4 / 3; /* Position right column, below header. */
    }
}

.incentives {
    @media (min-width: theme('screens.md')) {
        grid-area: 3 / 1 / 4 / 2; /* Position left column, below image. */
    }
}
