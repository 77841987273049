.dialog {
    overflow: auto;
    text-align: left;
    width: 45rem;
    max-width: 90vw;
    max-height: 80vh;

    @media screen and (min-width: 766px) {
        max-height: 95vh;
    }
}

.dialogSmall {
    width: 30rem;
}
