.dialog {
    position: fixed;
    inset: 0;
    height: 100dvh;
    overflow: hidden;
    z-index: theme('zIndex.flyout');
}

.overlay {
    position: absolute;
    inset: 0;
    opacity: theme('opacity.90');
    background-color: theme('colors.layers.soft');
    pointer-events: none;
}

.flyout {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: transform theme('transitionDuration.fast') theme('transitionTimingFunction.easeIn');
    will-change: transform;
    overflow: hidden;
    background-color: theme('colors.layers.foreground');
}

.flyoutRight {
    margin-left: auto;

    @media screen and (min-width: theme('screens.sm')) {
        max-width: 23.4375rem;
        border-radius: theme('borderRadius.200') 0 0 theme('borderRadius.200');
    }
}

.flyoutLeft {
    margin-right: auto;

    @media screen and (min-width: theme('screens.sm')) {
        max-width: 23.4375rem;
        border-radius: 0 theme('borderRadius.200') theme('borderRadius.200') 0;
    }
}

.header {
    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    padding: theme('spacing.100');
    border-bottom: 1px solid theme('colors.decorators.subtle');
}

.backButton {
    @apply -m-050;

    padding: theme('spacing.050');
}

.close {
    flex-shrink: 0;
    padding: theme('spacing.050');
}

.inner {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}
