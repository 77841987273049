.nav {
    display: none;

    @media screen and (min-width: theme('screens.lg')) {
        display: block;
        border-bottom: theme('topNavigation.border');
        background-color: theme('topNavigation.background');
        z-index: theme('zIndex.400');
    }
}

.listItem {
    position: static;

    &:hover,
    &:focus-within,
    &:focus {
        div,
        ul {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }
    }
}

.link {
    @apply type-090;

    display: flex;
    gap: theme('topNavigationLink.spacing');
    align-items: center;
    padding: theme('topNavigationLink.padding');
    color: theme('topNavigationLink.default.textColor');
    line-height: theme('lineHeight.none');
    background-color: theme('topNavigationLink.default.background');
}

.linkActive {
    position: relative;
    font-weight: theme('fontWeight.700');
    color: theme('topNavigationLink.active.textColor');
    background-color: theme('topNavigationLink.active.background');

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0.25rem;
        width: 0.25rem;
        height: 0.25rem;
        margin: 0 auto;
        background-color: theme('colors.content.highlight');
        border-radius: theme('borderRadius.full');
    }
}
