.form_field__wrapper {

    /* font-size: 14.22px; */

    /* color: #184363; */
    transition: opacity 0.3s linear;

    &.form_field__wrapper__disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    > * + * {
        margin-top: 0.375rem;
    }
}