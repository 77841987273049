.formField {
    @apply appearance-none;

    width: 100%;
    padding: theme('fields.padding.default');
    border: theme('border.light');
    border-radius: theme('borderRadius.100');
    background-color: theme('colors.layers.foreground');
    font-size: theme('fontSize.100');
    line-height: initial;
    transition: border-color 100ms linear;

    &::placeholder {
        color: theme('colors.content.quiet');
    }

    &:input-placeholder {
        color: theme('colors.content.quiet');
    }

    &::input-placeholder {
        color: theme('colors.content.quiet');
    }

    &:focus,
    &:hover {
        outline: none;
        border-color: theme('border.light');
    }

    &[type='date'] {
        cursor: text;
    }
}

.formSearchResultsList {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    max-height: 18.75rem;
    border-radius: theme('borderRadius.100');
    background-color: theme('colors.layers.foreground');
    box-shadow: theme('card.default.shadow');
    overflow: auto;
    z-index: theme('zIndex.500');

    &::-webkit-scrollbar-thumb {
        background-color: theme('colors.decorators.subtle');
        border: 4px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar {
        width: 1rem;
    }
}

.formSearchResultsListRounded {
    > li {
        &:first-child {
            button {
                border-radius: theme('borderRadius.100') theme('borderRadius.100') 0 0;
            }
        }

        &:last-child {
            button {
                border-radius: 0 0 theme('borderRadius.100') theme('borderRadius.100');
            }
        }
    }
}

.formSearchResult {
    @apply type-080 font-main;

    width: 100%;
    margin: 0;
    padding: theme('spacing.050') theme('spacing.075');
    border-radius: 0;
    text-align: left;

    &:hover,
    &:focus-visible {
        @apply outline-2 focus:outline-actions-secondary;

        color: theme('colors.actions.secondary');
    }

    span {
        display: inline;
        white-space: nowrap;
    }
}

.formSearchNoResults {
    @apply type-080 space-y-050;

    padding: theme('spacing.050') theme('spacing.075');
    color: theme('colors.status.error.default');
    text-align: left;
}
