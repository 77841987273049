.image {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    img {
        width: 4rem;
        height: 4rem;
        object-fit: contain;
    }
}
