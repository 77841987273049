.incrementor {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;

    & > span:first-child {
        top: -100%;
    }

    & > span:nth-child(2) {
        top: 0;
    }

    & > span:last-child {
        top: 100%;
    }
}

.number {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    line-height: theme('lineHeight.none');
    padding-bottom: 0.09375rem; /* 1.5px - 2px is too much for safari, 1px is not enough for chrome. */
}

.animateUp {
    /* Add the animation here otherwise it will animate the number back on it's self once the animation is complete. */
    & > span {
        transition: top theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    }

    & > span:first-child {
        top: 0;
    }

    & > span:nth-child(2) {
        top: 100%;
    }
}

.animateDown {
    /* Add the animation here otherwise it will animate the number back on it's self once the animation is complete. */
    & > span {
        transition: top theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    }

    & > span:nth-child(2) {
        top: -100%;
    }

    & > span:last-child {
        top: 0;
    }
}
