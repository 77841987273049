.callout {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.200');
    margin-top: theme('spacing.100');

    @media (min-width: theme('screens.md')) {
        flex-direction: row;
        align-items: center;
    }
}
