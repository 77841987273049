/**
 * This file is still in use where there is a problem
 * with the stacking order of the rendered HTML.
 * @see https://app.clickup.com/t/8693p5n5t
 */
.overlay {

    @apply appearance-none bg-layers-canvas bg-opacity-90;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0;
    transition-property: opacity;
    z-index: theme('zIndex.overlay');
    pointer-events: none;
}

.fixed {
    position: fixed;
    margin-top: 0;
}

.animateIn {
    opacity: 1;
    transition-duration: theme('transitionDuration.default');
    transition-timing-function: theme('transitionTimingFunction.easeIn');
    pointer-events: auto;
}

.animateOut {
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.easeOut');
    pointer-events: none;
}