.dropdown {
    position: absolute;
    top: calc(100% + 1px);
    min-width: 21.25rem;
    padding: theme('dropdown.padding');
    border: theme('dropdown.border');
    border-radius: theme('dropdown.borderRadius');
    background-color: theme('dropdown.background');
    transform: translateY(-1rem);
    transition: transform theme('transitionDuration.medium') theme('transitionTimingFunction.default'),
        opacity theme('transitionDuration.medium') theme('transitionTimingFunction.default');
    opacity: 0;
    z-index: 600;
    pointer-events: none;
    box-shadow: theme('dropdown.shadow');
}

button.dropdownItem,
.dropdownItem {

    @apply type-paragraph type-090 leading-800;

    display: block;
    padding: theme('dropdownItem.padding');
    border-radius: theme('dropdownItem.borderRadius');
    box-sizing: border-box;

    &:hover {
        background-color: theme('dropdownItem.hover.background');
        color: theme('dropdownItem.hover.textColor');
    }
}
