.container {
    padding: 0 theme('container.gutters.small');
    margin-left: auto;
    margin-right: auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

.spacing {
    margin-top: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}

.qrCode {
    @media (max-width: theme('screens.md')) {
        width: 4.5rem;
        height: 4.5rem;
    }
}

.howItWorksWithPrescription {
    margin-top: -25.375rem;

    @media (min-width: theme('screens.md')) {
        margin-top: -13.125rem;
    }

    @media (min-width: theme('screens.lg')) {
        margin-top: -9.375rem;
    }

    & > div {
        padding-top: 26.875rem;

        @media (min-width: theme('screens.md')) {
            padding-top: 14.125rem;
        }

        @media (min-width: theme('screens.lg')) {
            padding-top: 9.375rem;
        }
    }
}
