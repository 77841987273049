.card {

    position: fixed;

    bottom: 48px;

    left: 0;

    display: flex;

    justify-content: space-between;

    align-items: center;

    gap: theme('spacing.050');

    width: 100vw;

    padding: theme('spacing.075');

    background-color: theme('colors.layers.foreground');

    border-width: 1px 0;

    border-style: solid;

    border-color: #D6E3F2;
}

.treatmentName {

    white-space: nowrap;

    overflow: hidden;

    text-overflow: ellipsis;
}
