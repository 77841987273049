.card {

    @apply  flex
        flex-row
        items-center
        justify-start
        bg-[theme('card.bordered.background')]
        gap-x-050;

}


.image {

    @apply  w-[theme('icon.sizes.large')]
        h-[theme('icon.sizes.large')];
}

.label {

    @apply  flex-1;
}