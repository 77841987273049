.img {
    flex-shrink: 0;
    width: theme('sizes.200');
    height: theme('sizes.200');
}

.tag {
    display: inline-block;
    padding: theme('tag.padding.medium');
    /* Cant use them as it has no opacity - background: theme('tag.promotionSale.color'); */
    background: rgba(255, 84, 157, 0.5);
    border-radius: theme('tag.borderRadius');
    /* Wrong color - color: theme('tag.promotionSale.textColor'); */
    color: theme('colors.content.foundation');
}

.dot {
    flex-shrink: 0;
    display: inline-block;
    border-radius: theme('borderRadius.full');
    width: theme('sizes.025');
    height: theme('sizes.025');
    background-color: theme('colors.decorators.subtle');
}

.expanded {
    .img {
        width: theme('sizes.400');
        height: theme('sizes.400');
    }

    h6 {
        @apply type-090;
    }
}
