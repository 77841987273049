.mobileBubblesBasket {

    @apply drop-shadow-md;

    position: fixed;
    bottom: calc(theme('spacing.100') + 56px);
    right: theme('spacing.100');

    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    border: none;
    border-radius: 100%;
    background-color: theme('colors.neutrals.white');
    line-height: 1;
    color: theme('colors.neutrals.white');
    transform: scale(0.5);
    opacity: theme('opacity.0');
    transition-property: theme('transitionProperty.transform'), theme('transitionProperty.opacity');
    transition-timing-function: theme('transitionTimingFunction.easeOut');
    cursor: pointer;
    z-index: theme('zIndex.1');

    &.mobileBubblesBasketShow {
        opacity: theme('opacity.100');
        transform: scale(1);
        transition-property: theme('transitionProperty.transform'), theme('transitionProperty.opacity');
        transition-duration: theme('transitionDuration.fast');
    }

    @media screen and (min-width: 767px) {
        display: none;
    }
}

.aMobileBubblesBasketSave {
    opacity: theme('opacity.100');
    transform: scale(1);
    transition-property: theme('transitionProperty.transform'), theme('transitionProperty.opacity');
    animation: aMobileBubblesBasketSave theme('transitionDelay.300') theme('transitionTimingFunction.easeOut') 2600ms;
    animation-fill-mode: forwards;
}

.mobileBubblesCount {

    @apply drop-shadow-md font-600 type-090;
    display: flex;
    justify-content: center;
    top: 0;
    position: absolute;
    right: -0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 100%;
    background: theme('colors.promotion.sale');
    color: theme('colors.neutrals.white');
    opacity: theme('opacity.0');
    transform: translateY(0.6rem);
    transition-property: theme('transitionProperty.transform'), theme('transitionProperty.opacity');
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.easeOut');
    line-height: 100%;

    &.mobileBubblesCountShow {
        opacity: theme('opacity.100');
        transition-delay: theme('transitionDelay.300');
        animation: bubble-count-in theme('transitionDuration.default') theme('transitionTimingFunction.easeOut') theme('transitionDelay.300');
        animation-fill-mode: forwards;
    }
}

.aMobileBubblesSaveOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 100%;
    background-color: theme('colors.status.success.default');
    color: theme('colors.neutrals.white');
    opacity: theme('opacity.0');
    transform: scale(0);
    transition-property: theme('transitionProperty.opacity'), theme('transitionProperty.transform');
    animation: aMobileBubblesSaveOverlay theme('transitionDelay.700') theme('transitionTimingFunction.easeOut') theme('transitionDuration.slow');
    animation-fill-mode: forwards;
}

@keyframes bubble-count-in {

    0% {
        transform: translateY(0.6rem);
    }

    80% {
        transform: translateY(-0.1rem);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes aMobileBubblesBasketSave {

    0% {
        opacity: theme('opacity.100');
        transform: scale(1);
    }

    100% {
        opacity: theme('opacity.0');
        transform: scale(0.5);
    }
}

@keyframes aMobileBubblesSaveOverlay {

    0% {
        opacity: theme('opacity.0');
        transform: scale(0);
    }

    57% {
        opacity: theme('opacity.100');
        transform: scale(1.08);
    }

    100% {
        opacity: theme('opacity.100');
        transform: scale(1);
    }
}