.panel {
    @apply space-y-rhythm-medium;

    width: 100%;
    margin: theme('spacing.rhythm-medium') auto;
    padding: 0 theme('container.gutters.small');
    margin: theme('spacing.rhythm-medium') auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium')
    }

    @media (min-width: theme('screens.lg')) {
        @apply space-y-rhythm-large;

        margin: theme('spacing.rhythm-large') auto;
    }
}

.description {
    max-width: theme('container.sizes.small');
    margin: theme('spacing.025') auto 0 0;
}
