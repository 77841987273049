.title {
    height: 1.875rem;
}

.tag {
    height: 1.25rem;
    max-width: 7rem;
}

.label {
    height: 1rem;
}

.option {
    height: 3rem;
}

.button {
    height: 4rem;
}
