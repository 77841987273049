.basketCount {
    @apply type-emphasis type-090;

    position: absolute;
    display: flex;
    justify-content: center;
    top: 0.25rem;
    right: 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: theme('borderRadius.full');
    background: theme('colors.promotion.sale');
    color: theme('colors.neutrals.white');

    & > span {
        font-size: inherit;
        line-height: theme('lineHeight.200');
    }
}
