.gutters {
    padding-top: theme('rhythm.small');
    padding-bottom: theme('rhythm.small');

    @media screen and (min-width: theme('screens.md')) {
        padding-top: theme('rhythm.medium');
        padding-bottom: theme('rhythm.medium');
    }

    @media screen and (min-width: theme('screens.lg')) {
        padding-top: theme('rhythm.large');
        padding-bottom: theme('rhythm.large');
    }
}

.currentPanel {
    flex-basis: 60%;
    padding-left: theme('container.gutters.small');
    padding-right: theme('container.gutters.small');

    @media screen and (min-width: theme('screens.md')) {
        padding-left: theme('container.gutters.medium');
        padding-right: theme('container.gutters.medium');
    }
}

.aside {
    position: relative;
    display: none;
    background: theme('colors.layers.canvas');

    @media screen and (min-width: theme('screens.md')) {
        display: block;
        flex-basis: 40%;
    }

    /* &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background: theme('colors.layers.canvas');
    } */
}
