.megamenu {
    position: absolute;
    left: 0;
    top: calc(100% + 1px);
    max-height: calc(100vh - 10rem);
    box-shadow: theme('card.default.shadow');
    border: theme('card.default.border');
    border-radius: theme('card.borderRadius.default');
    background-color: theme('card.default.background');
    white-space: normal;
    transform: translateY(-1rem);
    transition: transform theme('transitionDuration.medium') theme('transitionTimingFunction.default'),
        opacity theme('transitionDuration.medium') theme('transitionTimingFunction.default');
    opacity: 0;
    z-index: 600;
    pointer-events: none;
    overflow: auto;
}

.megamenuFindTreatmentBorder {
    padding-bottom: theme('spacing.050');
    border-bottom: theme('topNavigation.border');
}

.megamenuGroupHeader {
    display: flex;
    align-items: center;
    gap: theme('spacing.100');

    &:after {
        content: '';
        display: block;
        background-image: url('/images/icons/IconChevronRight.svg');
        opacity: 0;
        transform: translateX(-0.5rem);
        transition: transform theme('transitionDuration.fast') theme('transitionTimingFunction.default'),
            opacity theme('transitionDuration.fast') theme('transitionTimingFunction.default');
        width: 10px;
        height: 10px;
        margin-left: auto;
        background-repeat: no-repeat;
        background-size: 8px 8px;
        background-position: 0px 50%;
    }

    &:hover:after {
        content: '';
        display: block;
        background-image: url('/images/icons/IconChevronRight.svg');
        opacity: 1;
        transform: translateX(0);
    }
}

.conditionOffers {
    @apply flex-shrink-0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: theme('spacing.200');
    border-left: 1px solid theme('colors.decorators.subtle');
}